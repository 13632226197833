/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('yd-pullrefresh', {
    ref: "pullrefreshList_",
    attrs: {
      "callback": _vm.refreshList
    }
  }, [_vm.list && _vm.list.length > 0 ? _c('div', _vm._l(_vm.list, function (item) {
    return _c('div', {
      key: item.id
    }, [_c('div', {
      staticClass: "item-a",
      on: {
        "click": function ($event) {
          return _vm.toTask(item);
        }
      }
    }, [_vm.getStatusHtml(item) ? _c('div', {
      staticClass: "daibanStatus",
      domProps: {
        "innerHTML": _vm._s(_vm.getStatusHtml(item))
      }
    }) : _vm._e(), _c('div', {
      staticClass: "item-a-flex"
    }, [_c('div', [_c('img', {
      attrs: {
        "src": require("../../../../static/img/boy.jpg")
      }
    })]), _c('div', [_c('h1', [_vm._v(_vm._s(item.description))]), _c('ul', {
      staticClass: "instlist-ul-list"
    }, [_c('li', [_c('span', [_vm._v("停留时长")]), _c('span', [_vm._v(_vm._s(item.stayTime))])]), _c('li', [_c('span', [_vm._v("当前节点")]), _c('span', [_vm._v(_vm._s(item.name))])]), _c('li', [_c('span', [_vm._v("当前节点处理人")]), _c('span', [_vm._v(_vm._s(item.assigneeNames))])]), _c('li', [_c('span', [_vm._v("创建时间")]), _c('span', [_vm._v(_vm._s(item.createTime))])])])])])])]);
  }), 0) : _vm._e(), _c('list-more', {
    attrs: {
      "list": _vm.list,
      "total": _vm.total
    },
    on: {
      "loadMore": function ($event) {
        return _vm.loadList(false, true);
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };