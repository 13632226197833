/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import ToDoList from '@/components/bpm/MyTask/ToDoList';
import router from '@/router/index';
import { RxUtil } from "@/assets/util.js";
export default {
  name: 'my-toDo',
  data() {
    return {
      searchName: "",
      showList: false,
      showCancelBtn: false,
      showHisMenu: true,
      taskHis: []
    };
  },
  components: {
    ToDoList
  },
  methods: {
    search() {
      // this.getImg();
      this.$store.commit('setToDoSearchVal', this.searchName);
    },
    clear() {
      this.searchName = "";
      this.$store.commit('setToDoSearchVal', this.searchName);
    },
    getImg() {
      var url = _baseUrl + "/mobile/oa/getUserImg.do" + this.searchName;
      this.$ajax.get(url).then(response => {});
    },
    loadHis() {
      this.taskHis = RxUtil.getSearchHis('taskname');
    },
    onFocus() {
      this.showCancelBtn = true;
      this.loadHis();
      this.showList = true;
      this.showHisMenu = true;
    },
    closeSearch() {
      this.searchName = "";
      this.showList = false;
      this.showCancelBtn = false;
      this.search();
    },
    searchTask(no_add_his) {
      if (this.searchName && this.searchName.trim()) {
        if (!no_add_his) {
          this.taskHis = RxUtil.addSearchHis(this.searchName, 'taskname');
        }
        this.showHisMenu = false;
        this.showList = false;
        this.showCancelBtn = false;
        this.search();
      }
    },
    clearSearchVal() {
      this.searchName = "";
      this.showHisMenu = true;
      this.search();
    },
    clearHis() {
      RxUtil.clearSearchHis("taskname");
      this.taskHis = [];
    },
    hisClick(val) {
      this.searchName = val;
      this.searchTask(true);
    }
  },
  computed: {
    toDoSearch: function () {
      return this.$store.state.searchModule.toDoSearch;
    }
  }
};